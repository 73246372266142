<template>
    <div class="record">
        <headbar title="交易LBD"></headbar>
        <div class="taps">
            <div class="taps-item" @click="$router.push('Share')">
                <img src="@/assets/img/base/c1.png" class="taps-icon" />
                <div>邀请</div>
            </div>
            <div class="taps-item" @click="$router.push('Chongzhi')">
                <img src="@/assets/img/base/c2.png" class="taps-icon" />
                <div>存入</div>
            </div>
            <div class="taps-item" @click="$router.push('Tixian')">
                <img src="@/assets/img/base/c3.png" class="taps-icon" />
                <div>提取</div>
            </div>
            <div class="taps-item" @click="$router.push('Zhuanzhang')">
                <img src="@/assets/img/base/c4.png" class="taps-icon" />
                <div>转账</div>
            </div>
        </div>

        <charts></charts>

        <trade ref="tradeRef" @refresh="$refs.mintageRef.load()"></trade>
    </div>
</template>
  
<script>
import Charts from '@/views/home/components/Charts.vue'
import Trade from '@/views/home/components/Trade.vue'

export default {
    name: '',
    components: {
        Charts,
        Trade
    },
    setup() {
        return {
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.$refs.tradeRef.load()
        },
    }
}
</script>
  
<style scoped>
.record {
    padding: 10px;
    min-height: 100vh;
    background-color: #080535
}

.taps {
    margin-top: 20px;
    display: flex;
}

.taps-item {
    flex: 1;
    text-align: center;
    color: #fff;
}

.taps-icon {
    width: 63px;
}
</style>