<template>
  <div class="trade">
    <div class="header">
      <div style="display: flex;">
        <!-- <img src="@/assets/img/base/i4.png" class="header-icon" /> -->
        <img src="@/assets/img/logo.png" class="header-icon" />
        <div class="title">{{ $t('交易') }}WLBD</div>
      </div>
      <img src="@/assets/img/base/i5.png" class="header-icon" @click="$emit('onClickShowCharts', true)" />
    </div>

    <div class="content">
      <div class="form">
        <div class="input-wrap">
          <div class="input-label">{{ $t('币价') }}</div>
          <div class="input">
            <q-input borderless v-model="swapInfo.tokenPrice" dense input-style="color:#fff" readonly />
          </div>
          <div class="input-label">USDT</div>
        </div>
        <div class="input-wrap q-mt-sm">
          <div class="input-label">{{ $t('数量') }}</div>
          <div class="input">
            <q-input borderless type="number" v-model="num" dense input-style="color:#fff" :placeholder="$t('请输入')"
              @update:model-value="numChange" />
          </div>
          <div class="input-label">WLBD</div>
        </div>
        <div class="tabs">
          <div class="tabs-item" :class="{ 'tabs-active': active == item.value }" v-for="(item, index) in tabs"
            :key="item.value" @click="tabsChange(index)">
            {{ item.text }}
          </div>
        </div>
        <div class="input-wrap">
          <div class="input-label">{{ $t('金额') }}</div>
          <div class="input">
            <q-input borderless v-model="swapResult.jine" dense input-style="color:#fff" readonly />
          </div>
          <div class="input-label">USDT</div>
        </div>
        <div class="data">
          <div>{{ $t('可用') }}WLBD：{{ swapInfo.lbd }}</div>
          <div>{{ $t('实际获得') }}：{{ swapResult.sjhd }}U</div>
          <div>{{ $t('回流国库') }}：{{ swapResult.hlgk }}U</div>
        </div>
        <div class="btn" @click="swap">{{ $t('闪兑') }}</div>
      </div>

      <div class="market">
        <div class="market-item item-bg2">
          <div class="market-value item-value-2">{{ swapInfo.pool1 }}</div>
          <div class="market-label ">{{ $t('国库USDT剩余总量') }}</div>
          <div class="item-btn" @click="$router.push('GuokuRecord')">{{ $t('查看详情') }}</div>
        </div>
        <div class="market-item item-bg1">
          <div class="market-value item-value-1">{{ swapInfo.pool2 }}</div>
          <div class="market-label">{{ $t('国库LBD剩余总量') }}</div>
          <div class="item-btn" @click="$router.push('Zhubichi')">{{ $t('查看详情') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: 'Trade',
  components: {},
  setup() {
    return {
      us: ref(null),
      swapInfo: ref({}),
      num: ref(""),
      swapResult: ref({
        jine: 0,
        sjhd: 0,
        hlgk: 0
      }),
      tabs: [
        { text: "25%", value: 25 },
        { text: "50%", value: 50 },
        { text: "75%", value: 75 },
        { text: "100%", value: 100 },
      ],
      active: ref("")
    }
  },
  methods: {
    load() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      this.getSwapInfo()
    },
    tabsChange(index) {
      this.active = this.tabs[index].value
      this.num = this.swapInfo.lbd * this.active / 100
      this.numChange()
    },
    numChange() {
      let _this = this
      if (_this.num) {
        _this.$request.post(
          "api/Token/SwapResult",
          {
            
            userid: _this.us.userid,
            count: _this.num,
            price: _this.swapInfo.tokenPrice
          },
          (res) => {
            if (res.data.code == 0) {
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              });
              return
            }
            let data = res.data.data
            _this.swapResult = data
          }
        )
      } else {
        _this.jine = 0
      }
    },
    getSwapInfo() {
      let _this = this
      _this.$request.post(
        "api/Token/GetSwapInfo",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.swapInfo = data
        }
      )
    },
    swap() {
      let _this = this
      if (this.num == "") {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要闪兑吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$i18n.t("闪兑中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/Token/Swap",
            {
              
              userid: _this.us.userid,
              amount: _this.num,
              signStr: signStr,
              address: _this.us.address
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              });
              if (res.data.code == 100) {
                _this.num = ""
                _this.numChange()
                _this.getSwapInfo()
                _this.$emit('refresh')
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.trade {
  margin-top: 15px;
  padding: 25px 15px 10px;
  border-radius: 20px;
  background-color: #2f1d51;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-icon {
  width: 25px;
}

.title {
  margin-left: 10px;
  font-size: 18px;
  color: #fff;
}

.content {
  margin-top: 20px;
  display: flex;
}

.market {
  display: flex;
  flex-direction: column;
}

.market-item {
  position: relative;
  flex: 1;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
}

.market-item:nth-child(2) {
  margin-top: 2px;
}

.item-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  margin: 0 10px 6px;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px;
  scale: 0.9;
}

.item-bg1 {
  background: url('../../../assets/img/trade/trade-img1.png');
  background-size: 100% 103%;
}

.item-bg2 {
  background: url('../../../assets/img/trade/trade-img2.png');
  background-size: 100% 103%;
}

.market-label {
  max-width: 110px;
  overflow-x: auto;
  margin-top: 30px;
  font-size: 12px;
  scale: 0.9;
  color: #aaaaaa;
}

.market-value {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  width: 120px;
  overflow-x: auto;
  text-align: center;
}

.form {
  margin-right: 10px;
}

.input-wrap {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0D0B18;
  border-radius: 12px;
}

.input-label {
  padding: 6px 0;
  font-size: 14px;
  color: #aaaaaa;
}

.input {
  padding: 0 10px;
  flex: 1;
}

.input:deep(.q-field--dense .q-field__control) {
  height: 30px;
}

.form-label {
  padding: 4px 0;
  font-size: 14px;
  color: #aaaaaa;
  text-align: right;
}

.tabs {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.tabs-item {
  width: 23%;
  height: 20px;
  border-radius: 3px;
  color: #aaaaaa;
  text-align: center;
  background-color: #0D0B18;
}

.tabs-active {
  color: #fff;
  background-color: #e76efa;
}

.data {
  margin-top: 10px;
  color: #fff;
}

.btn {
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}
</style>